<template>
  <div
    class="d-flex"
    style="gap: 8px"
  >
    <button-dashed class="btn-custom btn-custom--clear">
      <feather-icon
        v-b-tooltip.noninteractive.hover
        icon="LClearIcon"
        size="16"
        :title="$t('Reset all filters')"
      />
    </button-dashed>
    <b-button
      v-for="(status, idx) in statusFilterTab"
      :key="idx"
      :class="`btn-custom btn-custom--${status.icon}`"
      style="border: none"
    >
      <!-- on active ==>   btn-custom--active   -->
      <feather-icon
        v-b-tooltip.noninteractive.hover
        :title="status.title"
        :icon="status.icon"
        size="18"
        style="fill: #646464;"
      />
    </b-button>
    <b-dropdown
      size="md"
      no-caret
      variant="customClassButton"
      class="paddingCustom"
    >
      <template
        #button-content
      >
        <div class="dropNewClass">
          <feather-icon
            icon="LPrintIcon"
            size="18"
            class="lightIcon m-0"
          />
          <span>
            {{ $t('Print') }}
          </span>
          <feather-icon
            icon="LArrowDownIcon"
            size="11"
            class="lightIcon m-0"
          />
        </div>
      </template>
      <b-dropdown-item>First Action</b-dropdown-item>
    </b-dropdown>
    <feather-icon
      icon="LAddButtonGreenIcon"
      size="35"
      class="lightIcon m-0 cursor-pointer"
      @click="gotoNewPage({ name: 'home-orders-rental-sales-create' }, $event)"
    />
  </div>
</template>

<script>
// Components
import {
  BButton, BDropdown, BDropdownItem, VBTooltip,
} from 'bootstrap-vue'

// Configs
import config from '@/views/main/orders/config'
import ButtonDashed from '@core/components/b-button-dashed/ButtonDashed.vue'

export default {
  name: 'StatusFilterTab',
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    ButtonDashed,
    BButton,
    BDropdown,
    BDropdownItem,
  },
  setup() {
    const { statusFilterTab } = config()

    return {
      statusFilterTab,
    }
  },
}
</script>
<style lang="scss" scoped>
  .btn-custom {
    min-width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    background: #D9D9D9 !important;
    margin: 0;
    transition: all 0.2s ease;

    &--active {
      background-color: #646464 !important;
      transition: all 0.2s ease;
      & svg {
        fill: #fff !important;
      }
    }

    &--LPickIcon, &--LBoxIcon {
      & svg {
        position: relative;
        top: 3px;
      }
    }

    &--clear {
      background: transparent !important;
      & svg {
        fill: #D9D9D9;
      }

      &:hover {
        border-color: rgba(100, 100, 100, 0.8);
        & svg {
          fill: rgba(100, 100, 100, 0.8);
        }
      }
    }
  }
</style>
<style lang="scss">
.paddingCustom {
  border: 1px dashed #dde3e7;
  border-radius: 0.35rem;
  height: 36px;
  min-width: 115px;

  & .btn {
    padding: 7px 15.5px !important;
  }

  .dropdown-item {
    padding: 0.2rem 1.28rem;
  }
}

.dropNewClass{
  outline: none;
  display: flex;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  justify-content: center !important;
  font-size: 16px !important;
  color: #49454f;
  gap: 9px;
}
</style>
